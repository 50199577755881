<template>
  <div>
    <div v-show="editable&&!disabled" class="col float-right">
      <q-btn-group outline>
        <c-btn 
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="moc"
          mappingType="PUT"
          label="완료" 
          icon="check"
          @beforeAction="completeMoc"
          @btnCallback="completeMocCallback" />
        <c-btn label="삭제" icon="remove" @btnClicked="removeMoc" />
      </q-btn-group>
    </div>
    <c-tab
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param.sync="param"
          :moc.sync="moc"
          :disabled="disabled"
          :height="height"
          @saveCallback="saveCallback"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'moc-action-emergency',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopMocId: '',
        mocTypeCd: '',
        mocStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: 'action',
      tabItems: [
        { name: 'action', icon: 'pending_actions', label: '실시', component: () => import(`${'./mocActionEmergencyDetail.vue'}`), },
        { name: 'call', icon: 'campaign', label: '발의', component: () => import(`${'./initiativeEmergency.vue'}`), },
      ],
      moc: {
        sopMocId: '',  // MOC 일련번호
        mocNo: '',  // moc 관리번호
        plantCd: '',  // 사업장 코드
        initiativeUserId: '',  // 발의자 ID
        initiativeUserName: '',  // 발의자 명
        initiativeDeptCd: '',  // 발의 부서 코드
        initiativeDeptName: '',  // 발의 부서명
        mocTitle: '',  // 변경관리 제목
        mocSummary: '',  // 변경 개요
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocWriteLevelCd: null,  // 변경등급_작성
        mocConfirmLevelCd: null,  // 변경등급_검토
        processSatefySecureMeasures: '',  // 공정안전 확보 대책
        technicalBasisProcessDesign: '',  // 변경계획에 대한 공정 및 설계의 기술근거
        safetyReliabilityImprovementEffect: '',  // 안전성에 필요한 사항 및 신뢰성 향상 효과
        leaderOpinion: '',  // 팀장의견
        relatedLaws: '',  // 관련 볍령_텍스트
        processRiskAssessFlag: '',  // 공정위험성 평가 여부
        jobRiskAssessFlag: '',  // 작업위헝성 평가 여부
        mocPeriod: [],
        mocStartDate: '',  // 변경 시작일
        mocEndDate: '',  // 변경 종료일
        mocStepCd: '',  // 변경관리 단계
        mocActionDeptCd: '',  // 변경실행 부서코드
        mocActionResponsibilityUserId: '',  // 변경실행 책임자 ID
        mocCompleteCheckUserId: '',  // 변경완료 확인자 ID
        mocCompleteCheckDeptCd: '',  // 변경완료 확인 부서 코드
        mocCompleteOpinion: '',  // 변경완료 의견
        mocBenefitCds: '',  // 변경관리 기대효과_복수
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklists: [],
        checklistEquips: [],
        checklistDrives: [],
        equipments: [],
        deleteEquipments: [],
        reviews: [],
        committees: [],
        committeeReviewItems: [],
        committeeHold: {
          sopMocId: '',  // MOC 일련번호
          firstHoldDate: '',  // (최초) 개최일
          firstHoldArea: '',  // (최초) 개최장소
          firstReviewContents: '',  // (최초) 검토내용
          firstReviewResult: '',  // (최초) 검토결과
          professionalHoldDate: '',  // (전문가) 개최일
          professionalHoldArea: '',  // (전문가) 개최장소
          professionalReviewContents: '',  // (전문가) 검토내용
          professionalReviewResult: '',  // (전문가) 검토결과
          finalHoldDate: '',  // (최종) 개최일
          finalHoldArea: '',  // (최종) 개최장소
          finalReviewContents: '',  // (최종) 검토내용
          finalReviewResult: '',  // (최종) 검토결과
          changeApprovalFlag: '',  // 변경 승인 여부
          changeApprovalReason: '',  // 변경 승인 사유
          changeApprovalDate: '',  // 승인일자
          changeKeepFlag: '',  // 변경유지 여부
          changeKeepReason: '',  // 변경유지 사유
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
        },
        relatedWorks: [],
        prestartupImproves: [],
      },
      itemCds: {
        equip: [],
        checkDrive: [],
      },
      editable: true,
      completeUrl: '',
      deleteUrl: '',
      isComplete: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 60
      return numHeight + 'px';
    },
    checkComplete() {
      // 선정한 진행항목들이 전부 완료되었을 경우 사용자가 완료처리할 수 있도록 체크
      let returnData = true
      if (this.moc.relatedWorks && this.moc.relatedWorks.length > 0) {
        this.$_.forEach(this.moc.relatedWorks, relatedWork => {
          if (relatedWork.completeFlag !== 'Y') {
            returnData = false;
            return false;
          }
        })
      }
      return returnData;
    },
    disabled() {
      return this.param.mocStepCd !== 'ME00000001' || !this.param.sopMocId
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.moc.change.emergency.initiative.get.url
      this.completeUrl = transactionConfig.sop.moc.change.complete.url
      this.deleteUrl = transactionConfig.sop.moc.change.delete.url
      // code setting
      this.$comm.getComboItems('MOC_CHECK_TYPE_CD').then(_result => {
        let columns1 = this.$_.filter(_result, { attrVal1: 'equip' })
        let columns2 = this.$_.filter(_result, { attrVal1: 'check-dirve' })

        this.itemCds = {
          equip: this.$_.map(columns1, 'code'),
          checkDrive: this.$_.map(columns2, 'code'),
        }
      });
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.sopMocId) {
        this.$http.url = this.$format(this.detailUrl, this.param.sopMocId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.moc, _result.data);
          this.moc.mocPeriod = this.moc.mocStartDate && this.moc.mocEndDate ?
            [this.moc.mocStartDate, this.moc.mocEndDate] : []
        },);
      }
    },
    saveCallback() {
      this.getDetail();
    },
    completeMoc() {
      /**
       * ## 실시
       *  - 전부 완료되었는지 확인
       * 
       * ## 발의
       *  - 판정이 하나라도 이루어 졌는지?
       *  - 변경요소관리 정보가 올바르게 저장되었는지?
       */
      let isProgress = true;
      if (this.moc.checklistEquips && this.moc.checklistEquips.length > 0) {
        let isChecklist = false;
        this.$_.forEach(this.moc.checklistEquips, item => {
          for (let key in item) {
            if (this.$_.indexOf(this.itemCds.equip, key) > -1 && item[key] === 'Y') {
              isChecklist = true
              break;
            }
          }
          if (isChecklist) {
            return false;
          }
        })
        if (!isChecklist) {
          this.$_.forEach(this.moc.checklistDrives, item => {
            for (let key in item) {
              if (this.$_.indexOf(this.itemCds.checkDrive, key) > -1 && item[key] === 'Y') {
                isChecklist = true
                break;
              }
            }
            if (isChecklist) { 
              return false;
            }
          })
          if (!isChecklist) {
            isProgress = false;
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '판정이 이루어지지 않았습니다.\n\r체크리스트 중 하나 이상 판정을 해주십시오.',
              type: 'warning', // success / info / warning / error
            });
          }
        }
      }

      if (isProgress && !this.moc.mocConfirmLevelCd) {
        isProgress = false;
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '등급(검토)를 입력하세요.',
          type: 'warning', // success / info / warning / error
        });
      }

      if (isProgress && !this.checkComplete) {
        isProgress = false;
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '완료되지 않은 변경실시 항목이 존재합니다.\n\r시행여부 판단하신 후 완료 진행바랍니다.',
          type: 'warning', // success / info / warning / error
        });
      }
      if (isProgress) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '완료하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.moc.chgUserId = this.$store.getters.user.userId
            this.moc.mocStepCd = 'ME00000005'

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeMocCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.moc.mocStepCd
      })
    },
    removeMoc() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.moc.sopMocId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
